<template>
    <fragment>
        <section class="signUp">
            <div class="form__container">
                <div class="form__left">
                    <div class="form__title">
                        {{ $t('signup.title1') }}
                    </div>
                    <form class="form__box">
                        <div class="form__group">
                            <label class="form__name form__name-name" for="form-name">{{ $t('signup.text1') }}</label>
                            <input class="form__input" type="text" :placeholder="$t('signup.text2')" v-model="user.username"
                                v-validate="'required|min:5|max:12'" name="username">
                        </div>

                        <div class="form__group">
                            <label class="form__name form__name-email" for="form-email">{{ $t('signup.text3') }}</label>
                            <input class="form__input" type="text" :placeholder="$t('signup.text4')" v-model="user.phone"
                                v-validate="{ required: true, regex: /^[+][0-9]{3,4}[0-9]{3}[0-9]{5}$/ }" name="phone">
                        </div>

                        <div class="form__group">
                            <label class="form__name form__name-password" for="form-password">{{ $t('signup.text5')
                            }}</label>
                            <input class="form__input" type="password" :placeholder="$t('signup.text6')"
                                v-model="user.password"
                                v-validate="{ required: true, min: 8, max: 40, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/ }"
                                name="password" ref="password">
                        </div>

                        <div class="form__group">
                            <label class="form__name form__name-confirm" for="form-confirm">{{ $t('signup.text7') }}</label>
                            <input class="form__input" type="password" :placeholder="$t('signup.text8')"
                                v-validate="'required|confirmed:password'" name="confirm_password" data-vv-as="password">
                        </div>

                        <div class="form__features">
                            <div class="form__features-wrapper">
                                <label class="form__features-label" for="form-checkbox">
                                    {{ $t('signup.text9') }} <a href="../terms">{{ $t('signup.text10') }}</a>
                                    <input class="form__features-checkbox reg-checkbox" type="checkbox" id="form-checkbox"
                                        v-validate="'required'" name="terms">
                                    <span class="form__features-checkmark"></span>
                                </label>
                            </div>
                        </div>

                        <button class="form__button" type="button" @click="next()">
                            <span>{{ $t('signup.btn1') }}</span>
                        </button>
                    </form>

                    <div class="form__sub">
                        <div class="form__sub-text">{{ $t('signup.text11') }}</div>
                        <a class="form__sub-link" href="signin">{{ $t('signup.link1') }}</a>
                    </div>
                </div>

                <div class="form__right">
                    <img src="../assets/img/space_two.png" alt="">
                </div>
            </div>
        </section>
        <transition name="fade" v-if="submitted" appear>
            <div class="popap-verifi" style="position: fixed !important; display: flex; text-align: left;">
                <div class="popap-verifi-container-item popap-verifi-list-one popap-verifi-activ">
                    <div class="popap-verifi-item-info">
                        <div class="popap-verifi-loader">
                            <div class="popap-verifi-loader-border"></div>
                        </div>
                        <div class="popap-verifi-item-info-title">
                            {{ $t('signup.title2') }}
                        </div>
                        <div id="alertMessageMeta" class="popap-verifi-item-info-text"
                            style="max-height: 170px; overflow: hidden;">
                            <p v-if="sended">{{ $t('signup.text12') }}</p>
                            <p v-else>{{ $t('signup.text13') }}</p>
                            <br>
                            <div class="form__group">
                                <label for="form-email" class="form__name form__name-email">{{ sended ? $t('signup.text14')
                                    :
                                    $t('signup.text15') }}</label>
                                <input v-if="sended" class="form__input" type="text" :placeholder="$t('signup.text16')"
                                    v-model="user.code" v-validate="'required'" name="code">
                                <input v-else class="form__input" type="email" :placeholder="$t('signup.text17')"
                                    v-model="user.email" v-validate="'required|email|max:100'" name="email">
                                <div v-if="sended" class="form__sub"
                                    style="margin-top: 10px !important; justify-content: right;">
                                    <a v-if="timerCount > 0" class="form__sub-link blocked">{{ $t('signup.text18', {
                                        timer: timerCount
                                    }) }}</a>
                                    <a v-else @click="send()" class="form__sub-link">{{ $t('signup.text19') }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="popap-verifi-item-info-btn">
                            <button v-if="sended" @click="register()"
                                class="popap-verifi-item-info-btn-next popap-verifi-list-one-button popap-btn-upd">
                                {{ $t('signup.btn1') }}
                            </button>
                            <button v-else @click="send()"
                                class="popap-verifi-item-info-btn-next popap-verifi-list-one-button">
                                {{ $t('signup.btn2') }}
                            </button>
                            <button @click="close()"
                                class="popap-verifi-item-info-btn-cancel popap-verifi-btn-cancel-list-one">
                                {{ $t('signup.btn3') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </fragment>
</template>

<script>
import axios from 'axios'
import CONFIG from '../config/settings.js'
const API_URL = CONFIG.APIURL

export default {
    name: 'Register',
    data() {
        return {
            user: {
                username: null,
                email: null,
                password: null,
                phone: null,
                code: null
            },
            submitted: false,
            sended: false,
            successful: false,
            timerEnabled: false,
            timerCount: 0
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        }
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled)
                    setTimeout(() => {
                        this.timerCount--
                    }, 1000)
            },
            immediate: true
        }
    },
    created() {
        if (this.loggedIn) {
            window.location.replace(CONFIG.BASEURL + '/trading')
        }
    },
    methods: {
        next() {
            this.$validator.validate().then(isValid => {
                if (isValid) {
                    return axios.post(API_URL + 'auth/validate', {
                        username: this.user.username,
                        email: this.user.email
                    }).then(() => {
                        this.submitted = true
                    }).catch(err => {
                        this.$toast.error(err.response.data.message)
                    })
                } else {
                    let username = this.errors.has('username')
                    let phone = this.errors.has('phone')
                    let password = this.errors.has('password')
                    let confirm_password = this.errors.has('confirm_password')
                    if (username)
                        this.warning('username')
                    else if (!username && phone)
                        this.warning('phone')
                    else if (!username && !phone && password)
                        this.warning('password')
                    else if (!username && !phone && !password && confirm_password)
                        this.warning('confirm_password')
                    else
                        this.warning('terms')
                }
            })
        },
        send() {
            this.$validator.validate().then(isValid => {
                if (isValid && this.timerCount <= 0) {
                    return axios.post(API_URL + 'auth/code', {
                        username: this.user.username,
                        email: this.user.email
                    }).then(response => {
                        this.timerCount = 60
                        this.timerEnabled = true
                        this.sended = true
                        this.$toast.success(response.data.message)
                    }).catch(err => {
                        this.$toast.error(err.response.data.message)
                    })
                } else {
                    this.warning('email')
                }
            })
        },
        register() {
            this.$validator.validate().then(isValid => {
                if (isValid && this.user.code)
                    this.$store.dispatch('auth/register', this.user).then(
                        () => {
                            this.successful = true
                        },
                        error => {
                            this.$toast.error(error.response.data.message)
                            this.successful = false;
                        }
                    )
            })
        },
        warning(value) {
            if (this.errors.has(value))
                this.$toast.warning(this.errors.first(value))
        },
        close() {
            this.submitted = false
            this.user.email = null
        }
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .4s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>